@import "./../../common/styles";

$yellow: #fef160;
$blue: #4589e5;
$dark-blue: #2066e5;
$light-blue: #77a4f7;

$full-page-height: calc(100vh - 113px - 574px);
$transition-all: all 300ms cubic-bezier(0.4, 0, 0.2, 1);

/* *********** visual style starts *********** */
:root {
  font-size: 14px;
  --primaryColor: #802c2d;
  --primaryDarkColor: #6d0308;
  --secondaryColor: #f3e6d7;
}

a {
  color: inherit;
  cursor: pointer;
  text-decoration: none;
  transition: $transition-all;
}

.hide {
  display: none;
}

html {
  background: #fff;
  font-family: "Microsoft YaHei", Tahoma, "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica,
    Arial, sans-serif;
  margin: 0;
  overflow-x: hidden;
  width: 100%;
}

body {
  margin: 0;
  width: 100%;
}

body .body-wrapper {
  opacity: 0;
  transition: opacity 150ms ease-in;
  background-color: #fff;
  color: #707070;
}
body.ready .body-wrapper {
  opacity: 1;
}

input:focus {
  outline: 0;
}

.container {
  margin: auto;
  max-width: 1200px;
  width: 100%;
}

.svg-mask {
  &.resize {
    .image {
      transform: scale(0.71);
    }
  }

  .image {
    background-color: var(--primaryColor);
    mask-repeat: no-repeat;
    mask-position: center;
    margin: auto;
    transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
  }
}

.btn-color {
  background-color: $blue;
  border: 0;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 1rem;
  padding: 5px 15px;
  text-align: center;
  width: 120px;
  transition: $transition-all;
  &.hide {
    display: none;
  }
  &:focus {
    outline: 0;
  }
  &:hover {
    background-color: $dark-blue;
  }
}

a.color-link {
  color: var(--primaryColor);

  &:hover {
    text-decoration: underline;
  }
}

/* *********** header *********** */
header {
  .top-login {
    background-color: var(--primaryDarkColor);
    color: #fff;
    min-height: 35px;
    display: flex;
    align-items: center;
    padding: 5px 0;

    .login-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .date-time-wrapper {
        font-size: 12px;

        .date-time {
          margin-right: 5px;
        }
      }

      .login-box {
        display: inline-flex;

        &.hide {
          display: none;
        }
        input {
          background-color: rgba(255, 255, 255, 0.1);
          border: 1px solid rgba(255, 255, 255, 0.5);
          border-radius: 4px;
          color: #fff;
          font-size: 1rem;
          padding: 6px 10px 6px 25px;
          margin-left: 5px;
          width: 120px;
          &::placeholder {
            color: rgba(255, 255, 255, 0.6);
            text-align: right;
          }
        }
        .username {
          background: url(../img/input_username.svg) 4px center no-repeat;
        }
        .password {
          background: url(../img/input_pwd.svg) 4px center no-repeat;
        }
        .vcode {
          position: relative;
          input {
            padding: 6px 10px;
            &::placeholder {
              text-align: left;
            }
          }
          .verify-code {
            border-radius: 4px;
            position: absolute;
            right: 0;
            top: 2px;
            width: 54px;
            height: 88%;
          }
        }
        .forgot-pwd {
          border: 0;
          border-radius: 20px;
          padding: 2px 6px;
          color: #eaeaea;
          display: flex;
          align-items: center;
          white-space: nowrap;
          &:hover {
            color: $blue;
          }
        }
        .btn-color {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          margin-left: 5px;
          width: 78px;
          &.red {
            background-color: #b92328;
          }
          &.blue {
            background-color: #4589e5;
            width: 50px;
          }
          &.orange {
            background-color: #d68c00;
          }
          &:hover {
            background-color: $dark-blue;
          }
        }
      }
      .header-links {
        display: flex;

        &.hide {
          display: none;
        }
        ul {
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 3px 0;
          li {
            list-style: none;
            &:not(:last-child) {
              margin-right: 20px;
            }
            a {
              color: #fff;
              white-space: nowrap;
              &:hover {
                color: $yellow;
              }
            }
          }
        }
      }
    }
  }

  .top-nav {
    background-color: var(--primaryColor);
    color: #fff;
    .menu {
      display: flex;
      align-items: center;
      position: relative;
      .logo {
        img {
          height: 54px;
          padding: 5px;
        }
      }
      .nav {
        display: flex;
        align-items: center;
        flex-direction: row;
        margin-left: auto;
        .menu-button {
          height: 68px;
          width: 100px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          position: relative;
          transition: $transition-all;
          &:before {
            background-color: $yellow;
            content: "";
            height: 3px;
            width: 100%;
            position: absolute;
            top: 0;
            transform: scaleX(0);
            transition: $transition-all;
          }
          &.active,
          &:hover {
            background-image: linear-gradient(rgba(254, 241, 96, 0.6), rgba(254, 241, 96, 0.1));
            &:before {
              transform: scaleX(1);
            }
          }
          img {
            margin-bottom: 6px;
          }
          &.lottery:hover + .lottery-dropdown {
            opacity: 1;
            visibility: visible;
          }
        }
      }
    }
  }

  .lottery-dropdown {
    background: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(10px);
    border-radius: 8px;
    position: absolute;
    left: 0;
    top: 68px;
    opacity: 0;
    visibility: hidden;
    width: 100%;
    transition: $transition-all;
    z-index: 5;
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      &.hide {
        display: none;
      }
      .lottery-box {
        flex-basis: calc(100% / 7);
        text-align: center;
        &.resize {
          .image {
            transform: scale(0.71);
          }
        }

        .image {
          height: 100px;
          width: 100px;
          &:hover {
            background-color: $blue;
          }
        }
      }
    }
    &:hover {
      opacity: 1;
      visibility: visible;
    }
  }
}

/* *********** footer *********** */
footer {
  background-color: var(--secondaryColor);
  color: #916c6c;
  .info {
    display: flex;
    padding-top: 40px;
    .service-col {
      flex-basis: 50%;
      font-size: 1.2rem;
      .service-wrapper {
        width: 60%;
        .title {
          color: var(--primaryDarkColor);
          font-weight: bold;
        }
        .time-wrapper {
          display: flex;
          align-items: center;
          .time {
            color: var(--primaryDarkColor);
            display: flex;
            align-items: center;
            margin-left: auto;
            font-size: 1.6rem;
          }
        }
      }
      .cs-wrapper {
        display: flex;
        align-items: center;
        img {
          margin-right: 15px;
        }
        .phone-num {
          font-size: 1.6rem;
          letter-spacing: 1px;
        }
      }
    }
    .brand-col {
      flex-basis: 50%;
      .logo {
        img {
          height: 68px;
        }
      }
      .title {
        color: var(--primaryDarkColor);
        font-size: 1.2rem;
        font-weight: bold;
      }
      .brand-ability {
        div {
          margin-bottom: 20px;
        }
      }
    }
  }

  .progress-bar {
    width: 100%;
    height: 6px;
    background: #d0bdbd;
    box-sizing: border-box;
    border-radius: 20px;
    position: relative;
    margin-top: 8px;
    margin-bottom: 26px;
    &.progress1 span,
    &.progress2 span {
      background-color: $blue;
      position: absolute;
      left: 0;
      top: 0;
      height: 6px;
      border-radius: 20px;
      transform: scaleX(0);
      transform-origin: left;
    }
    &.progress1 span {
      animation: expand 3s ease-out forwards;
      width: 37%;
    }
    &.progress2 span {
      animation: expand 3s ease-out forwards;
      width: 60%;
    }
    @keyframes expand {
      to {
        transform: scaleX(1);
      }
    }
  }

  .payment {
    display: flex;
    align-items: center;
    font-size: 0.9rem;
    padding: 30px 0;
    .title {
      font-size: 1.2rem;
    }
    .col {
      flex-basis: 50%;
    }
    .payment-box-wrapper {
      display: flex;
      .payment-box {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        flex-basis: calc(100% / 8);
        img {
          margin-bottom: 12px;
        }
        &.title {
          font-size: 1.2rem;
          justify-content: initial;
        }
      }
    }
    .techsup-wrapper {
      .sgwin-wrapper {
        display: flex;
        align-items: center;
        img {
          margin-right: 20px;
          margin-top: 5px;
        }
      }
    }
  }

  .disclaimer {
    background-color: var(--primaryDarkColor);
    .disclaimer-wrapper {
      display: flex;
      line-height: 18px;
      padding: 30px 0;
      .left {
        flex-basis: 20%;
        div:first-child {
          font-size: 1.6rem;
          font-weight: bold;
          margin-bottom: 6px;
        }
      }
      .right {
        flex-basis: 80%;
      }
    }
  }
}

/* ***** sidebar ****** */
.left-sidebar {
  color: #fff;
  text-align: center;
  position: fixed;
  z-index: 4;
  left: 3px;
  top: 25%;
  width: 168px;
  .left-navigator {
    img {
      position: relative;
      z-index: 4;
    }
    .navi-title {
      font-size: 1.4rem;
      position: relative;
      z-index: 4;
    }
  }
  .left-qr-wrapper {
    background: url("../img/sidebar/navigator_bg.png") center top no-repeat;
    border-radius: 8px;
    height: 190px;
    margin: -35px auto 0 auto;
    position: relative;
    .appqr-wrapper {
      padding-top: 35px;
    }
    .qr-code {
      background-color: #fff;
      height: 75px;
      width: 75px;
      margin: auto;
    }
    .hpscan {
      font-size: 0.9rem;
    }
  }
}

.right-sidebar {
  position: fixed;
  z-index: 4;
  top: 50%;
  transform: translateY(-50%);
  right: 22px;

  > a {
    position: relative;
    margin-bottom: 5px;
  }

  .right-item {
    background-color: $blue;
    border-radius: 8px;
    box-sizing: border-box;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: $transition-all;
    .width-fit {
      width: 100%;
    }
    &:hover {
      background-color: $dark-blue;
    }
  }
}

.right-item {
  &:hover .sidebar-desc {
    transform: translate(0, -50%);
    opacity: 1;
    visibility: visible;
  }

  .sidebar-desc {
    min-width: 40px;
    max-width: 140px;
    display: block;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    text-decoration: none;
    position: absolute;
    top: 50%;
    transform: translate(-30%, -50%);
    right: 60px;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 6px;
    padding: 5px;
    text-align: center;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    font-size: 0.8125rem;
    color: #fff;
    //white-space: nowrap;
    transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);

    &:before {
      content: "";
      position: absolute;
      top: 50%;
      right: -10px;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      width: 0;
      height: 0;
      border-top: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 5px solid transparent;
      border-left: 5px solid rgba(0, 0, 0, 0.7);
    }

    img,
    .qr-code {
      display: block;
      max-width: 130px;
    }
    div {
      word-break: break-all;
    }
  }
}

/* *********** index *********** */
.banner {
  height: 540px;
  position: relative;
  .swiper-container {
    height: 100%;
    .swiper-wrapper {
      height: 100%;
      .swiper-slide {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 0;
        overflow: hidden;
        position: relative;
        img {
          height: 100%;
          z-index: 1;
          &.img-bg {
            position: absolute;
            width: 130%;
            height: auto;
            left: 50%;
            top: 50%;
            transform: translateX(-50%) translateY(-50%);
            filter: blur(10px);
            z-index: -1;
          }
        }

        .img-wrapper {
          height: 100%;
          position: relative;
          .btn-color {
            position: absolute;
            z-index: 1;
            bottom: 120px;
            animation: glowing 2s cubic-bezier(0.4, 0, 0.2, 1) infinite alternate;
          }
          .swiper-btn {
            right: 618px;
          }
          .swiper1-btn {
            left: 618px;
          }
          @keyframes glowing {
            0% {
              box-shadow: 0 0 10px $light-blue, 0 0 40px $light-blue, 0 0 80px #fff, 0 0 100px #fff;
            }
            100% {
              box-shadow: 0 0 2px $light-blue, 0 0 25px $light-blue, 0 0 50px #fff, 0 0 50px #fff;
            }
          }
        }
      }
    }
  }
}

.notice {
  background-color: var(--secondaryColor);
  height: 40px;
  line-height: 40px;
  .marquee-wrapper {
    display: flex;
    overflow: hidden;
    .notice-box {
      background: var(--primaryColor);
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 8px;
      white-space: nowrap;
      width: 400px;
      img {
        margin-left: 5px;
      }
    }
    .marquee-box {
      position: relative;
      box-sizing: border-box;
      line-height: 40px;
      height: 40px;
      padding: 0 8px;
      width: 92%;
      ul {
        overflow: hidden;
        zoom: 1;
        margin: 0;
        li {
          margin-right: 20px;
          float: left;
          height: 40px;
          line-height: 40px;
          text-align: left;
          display: inline;
          width: auto !important;
        }
      }
    }
  }
}

.hotgame {
  background-color: #fff;
  padding: 50px 0;
  .hotgame-wrapper {
    display: flex;
    justify-content: center;
    .col-left {
      width: 40%;
      .hotgame-desc-box {
        line-height: 1.6rem;
        text-align: center;
        &:not(:last-child) {
          margin-bottom: 70px;
        }
        .title {
          color: var(--primaryColor);
          font-size: 2rem;
          font-weight: bold;
          line-height: 2.4rem;
          margin-bottom: 12px;
          margin-top: 4px;
        }
        .btn-color {
          margin-top: 20px;
        }
        .img-platform .image {
          height: 30px;
          width: 110px;
        }
      }
    }
    .col-right {
      color: var(--primaryColor);
      text-align: center;
      width: 60%;
      .title {
        font-size: 2rem;
        font-weight: bold;
        margin-bottom: 5px;
      }
      .game-wrapper {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
        .game-box {
          flex-basis: calc(100% / 3);
          .resize {
            .image {
              &:hover {
                background-color: $blue;
                transform: scale(0.78);
              }
            }
          }
          .image {
            height: 120px;
            width: 120px;
            margin-bottom: 10px;
            &:hover {
              background-color: $blue;
              transform: scale(1.1);
            }
          }
        }
      }
      .game {
        margin-top: 20px;
        .swiper-pagination {
          position: unset;
        }
        .swiper-pagination-bullet-active {
          background: var(--primaryColor);
        }
      }
    }
  }
}

/* *********** faqs *********** */
.faq {
  background: url("../img/faq_bg.png") center top repeat-x #fff;
  padding: 30px;
  box-sizing: border-box;
  min-height: $full-page-height;
}

.faq-wrapper {
  background-color: #f0f0f0;
  display: flex;
  .faq-menu {
    border-right: 1px solid #e3e3e3;
    flex-basis: 17%;
    font-size: 1.2rem;
    .newbie {
      background-color: #b10000;
      color: #fff;
      padding: 18px;
      font-weight: bold;
      text-align: center;
    }
    .faq-menu-button {
      cursor: pointer;
      padding: 16px 40px;
      box-sizing: border-box;
      transition: $transition-all;
      &.active,
      &:hover {
        background-color: #fff;
      }
    }
  }

  .faq-content-col {
    background: url("../img/faq_sakura.png") top right no-repeat;
    background-position-y: 80px;
    flex-basis: 83%;
    padding: 20px 32px;
    a {
      color: var(--primaryColor);
      &:hover {
        color: var(--primaryDarkColor);
      }
    }
    h2 {
      border-bottom: 1px solid #656565;
      color: var(--primaryColor);
      padding-bottom: 5px;
    }
    h4 {
      margin-bottom: 8px;
    }
    p {
      margin-top: 0;
      margin-bottom: 5px;
    }
  }
}

/* *********** app *********** */
.app {
  background: url("../img/app_bg.png") center no-repeat / cover #fff;
  color: var(--primaryColor);
  min-height: $full-page-height;
  padding-top: 36px;
  text-align: center;

  .app-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    .col {
      flex-basis: calc(100% / 3);
    }
    .qr-col {
      background: url("../img/app_scroll.png") center no-repeat;
      text-align: center;
      height: 580px;
      .qr-content {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        margin: auto;
        height: 580px;
        width: 185px;
        .qr-code {
          background-color: #fff;
          height: 140px;
          width: 140px;
          margin: auto;
        }
        div {
          margin-bottom: 10px;
        }
        .download {
          color: #000;
        }
        .title {
          color: #c1151b;
          font-size: 1.6rem;
          font-weight: bold;
          margin-bottom: 10px;
        }
        .btn-color {
          padding: 5px 10px;
          white-space: nowrap;
          width: 60px;
          &.play {
            margin-left: 5px;
          }
        }
      }
    }
    .phone-col {
      .online-games {
        font-size: 1.3rem;
        font-weight: bold;
        margin-bottom: 20px;
      }
      .img-platform .image {
        mask-position: right;
        height: 30px;
        width: 70px;
      }
      .img-phone {
        animation: phone 3s cubic-bezier(0.4, 0, 0.2, 1) infinite alternate;
      }
      @keyframes phone {
        to {
          transform: translateY(-20px);
        }
      }
    }
  }
}

/* *********** affindex *********** */
.aff {
  background-color: #fff;
  box-sizing: border-box;
  min-height: $full-page-height;
  padding: 40px;
  text-align: center;
  .button-row {
    margin: 0 auto 15px auto;
    width: 70%;
    text-align: left;
  }
  .title-row {
    color: var(--primaryColor);
    margin-bottom: 20px;
    .subtitle {
      font-size: 1.8rem;
    }
    .title {
      font-size: 3rem;
      font-weight: bold;
    }
  }
  .win-row {
    background: url("../img/aff_bg.png") center no-repeat / contain #fff;
    .win-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .win-col {
      &:not(:last-child) {
        margin-right: 50px;
      }
      .title {
        color: var(--primaryColor);
        font-size: 1.6rem;
        font-weight: bold;
        margin-bottom: 15px;
      }
    }
  }
  .benefit-row {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    .benefit-col {
      &.left {
        color: var(--primaryColor);
        font-size: 1.2rem;
        margin-right: 30px;
        .title {
          font-size: 2rem;
          font-weight: bold;
          margin-bottom: 15px;
        }
        .badge {
          background: url("../img/aff_badge.png") center no-repeat;
          height: 310px;
          width: 202px;
          margin: 30px auto 0 auto;
          position: relative;
          img {
            position: absolute;
            top: 80px;
            left: 50%;
            transform: translateX(-50%);
            animation: blink 1s cubic-bezier(0.4, 0, 0.2, 1) infinite alternate;
          }
          @keyframes blink {
            0% {
              opacity: 0;
            }
            70%,
            100% {
              opacity: 1;
            }
          }
        }
      }
      &.right {
        text-align: left;
        .title {
          color: var(--primaryColor);
          font-size: 1.4rem;
          margin-bottom: 15px;
        }
        .list {
          display: flex;
          align-items: center;
          margin-bottom: 15px;
          svg {
            margin-right: 8px;
          }
        }
        .sublist {
          margin-left: 28px;
          div {
            margin-bottom: 10px;
          }
        }
      }
    }
  }
  .cooperation-row {
    margin-top: 50px;

    .cooperation-row-title {
      color: var(--primaryColor);
      font-size: 2rem;
      font-weight: bold;

      text-align: center;
      margin-bottom: 15px;
    }

    .cooperation-contact-list {
      width: 300px;
      margin: 0 auto;
    }

    .cooperation-list {
      display: flex;

      > div {
        text-align: left;
      }

      .cooperation-title {
        flex-basis: 150px;
        font-weight: bold;

        &:after {
          content: ":";
        }
      }

      .cooperation-qrcode {
        width: 150px;
        background: white;
      }
    }
  }
}

/* *********** register *********** */
.register {
  background: url("../img/faq_bg.png") center top no-repeat / cover fixed #fff;
  padding: 30px;
  min-height: $full-page-height;
  box-sizing: border-box;
}

.register-container {
  background-color: #fff;
  .reg-steps {
    width: 75%;
    margin: auto;

    .reg-steps-box {
      background-color: #ececec;
      border: 1px solid #c2c2c2;
      box-sizing: border-box;
      display: inline-block;
      float: left;
      line-height: 32pt;
      text-align: center;
      vertical-align: middle;
      width: calc(100% / 3);
      &.active {
        background: var(--primaryColor);
        color: #fff;
      }
      .steps-no {
        display: inline-block;
        vertical-align: middle;
        font-size: 18pt;
        color: #978e8e;
      }
      .steps-title {
        display: inline-block;
        vertical-align: middle;
        font-size: 10.5pt;
        color: #978e8e;
      }
      .steps-no.active,
      .steps-title.active {
        color: #fff;
      }
    }
  }

  .register-form {
    padding: 75px 0 30px 0;
    box-sizing: border-box;

    .row {
      display: flex;
      align-items: center;
      margin-bottom: 15px;

      &.button {
        justify-content: center;
        margin-top: 20px;
      }

      .text {
        flex-basis: 20%;
        text-align: right;
        margin-right: 20px;
      }
      .inp {
        flex-basis: 30%;
        margin-right: 10px;
        input {
          border: 1px solid #c0c0c0;
          border-radius: 4px;
          box-sizing: border-box;
          font-size: 1rem;
          padding: 2px 10px;
          height: 32px;
          width: 100%;
          -webkit-appearance: none;
          background: transparent;
          outline: none;
        }
      }
      .tip {
        flex-basis: 50%;
        font-size: 0.9rem;
        display: flex;
        align-items: center;
      }
    }
  }
}

/* ***** promo ****** */
.promo {
  background: url("../img/app_bg.png") center no-repeat / cover fixed #fff;
  padding: 30px;
  min-height: $full-page-height;
  box-sizing: border-box;
  .promo-wrapper {
    background-color: #fff;
    color: #333;
    padding: 10px;
    .icon {
      cursor: pointer;
      img {
        width: 100%;
      }
    }
    a {
      color: var(--primaryColor);
    }
    .sub-content-item-footer {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      height: 50px;
      margin-bottom: 20px;
    }
  }
}
